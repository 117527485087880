































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import { Seminar } from "@/core/models";
import Invitations from "@/components/common/Invitations.vue";
import { createHomeTour, Tour } from "@/core/utils/siteTour";
import SeminarList from "../components/SeminarList.vue";
import CreateSeminar from "../components/admin/CreateSeminar.vue";
import { hasPermissions } from "@/core/utils/auth";

@Component({ components: { Invitations, SeminarList, CreateSeminar } })
export default class SeminarsHome extends Vue {
  @Getter("seminars/selected") selected?: Seminar;
  @Getter("seminars/seminars") seminars!: Seminar[];
  @Getter("seminars/gettingAll") gettingAll!: boolean;
  @Getter("seminars/ownsSeminar") ownsSeminar!: boolean;

  @Getter("profile/getUserRole") role!: string;
  @Getter("profile/showTutorial") showTutorial!: boolean;
  @Getter("profile/tutorialStep") tutorialStep!: number;

  get canCreateSeminar() {
    return (
      hasPermissions(["teacher:default"]) ||
      this.ownsSeminar ||
      ["admin", "coach", "teacher"].includes(this.role)
    );
  }

  @Watch("seminars", { immediate: true })
  seminarsLengthChanged() {
    const from = this.$route.query.from as string | undefined;
    if (!this.seminars.length || !from) return;

    const id = this.selected ? this.selected.id : this.seminars[0].id;
    if (from === "dash") this.$router.replace(`/seminars/dashboard/${id}`);
    else if (from === "details")
      this.$router.replace(`/seminars/details/${id}`);
  }

  tour: Tour | null = null;
  startTour() {
    this.tour = createHomeTour();
    this.tour.start();
  }
  @Watch("$route", { immediate: true })
  routeChanged() {
    const { tour } = this.$route.query;
    if (tour === "true" && this.showTutorial && this.tutorialStep === 0)
      this.startTour();
  }

  created() {
    this.$store.dispatch("seminars/getAll");
  }
}
